export enum UserRole {
  SEP_ADMIN = 'SEP_ADMIN',
  ORGANIZATION_ADMIN = 'ORG_ADMIN',
}

export interface CurrentUserUIModel {
  username: string;
  email: string;
  role: UserRole;
  organization: {
    id: string;
    name: string;
    subscriptions: Array<{ id: string; numberOfLicenses: number }>;
    tenant?: { id: string };
  } | null;
  hasOpenPurchaseIntent: boolean;
}
