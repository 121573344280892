export enum SepSessionStorageKeys {
  SEP_IMPERSONATION_SESSION_DATA = 'sepImpersonationSessionData',
}

export interface SepImpersonationSessionData {
  organization: {
    id: string;
    name: string;
    subscriptions: Array<{ id: string; numberOfLicenses: number }>;
    tenant: { id: string };
  };
}
